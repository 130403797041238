@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: NeueBlack;
  src: url("./assets/fonts/NeueHaasDisplayBlack.ttf");
}
@font-face {
  font-family: NeueBold;
  src: url("./assets/fonts/NeueHaasDisplayBold.ttf");
}
@font-face {
  font-family: NeueLight;
  src: url("./assets/fonts/NeueHaasDisplayLight.ttf");
}
@font-face {
  font-family: NeueMedium;
  src: url("./assets/fonts/NeueHaasDisplayMediu.ttf");
}
@font-face {
  font-family: NeueRegular;
  src: url("./assets/fonts/NeueHaasDisplayRoman.ttf");
}
@font-face {
  font-family: NeueThin;
  src: url("./assets/fonts/NeueHaasDisplayThin.ttf");
}

.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 10px; /* Adjust padding to accommodate buttons at the top */
}

.carousel-container {
  display: flex;
  transition: transform 0.5s ease;
}

.card {
  min-width: calc(
    100% / 1.5 - 20px
  ); /* Use a fraction for larger width, adjust as needed */
  margin-right: 20px; /* Space between cards */
  height: 200px;
  position: relative;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.phone-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  transition: transform 0.5s ease;
}
.port-phone-image {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-45%, -45%);
  width: 100%;
  transition: transform 0.5s ease;
}

.card:hover .phone-image {
  transform: translate(-50%, -50%) scale(1.2);
}

.react-international-phone-input {
  width: 100% !important;
  background-color: rgba(
    255,
    255,
    255,
    0.05
  ) !important; /* Same as bg-opacity-5 */
  padding: 19px !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  outline: none !important; /* outline-none */
  color: rgba(255, 255, 255, 1) !important; /* text-white */
  font-size: 13px !important; /* text-[13px] */
  font-family: "NeueRegular", sans-serif; /* Assuming NeueRegular is loaded */
}

.react-international-phone-input::placeholder {
  color: rgba(
    255,
    255,
    255,
    0.7
  ) !important; /* Same as placeholder:text-white placeholder:text-opacity-70 */
}

.react-international-phone-country-selector-button {
  background-color: rgba(255, 255, 255, 0.05) !important;
  padding-top: 19px !important;
  padding-bottom: 19px !important;
  padding-left: 14px !important;
  padding-right: 14px !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.scrolling-banner {
  display: flex;
  white-space: nowrap; /* Ensure the items don’t wrap */
}

.scrolling-banner .animate-scroll {
  display: flex;
  white-space: nowrap;
  animation: scroll 30s linear infinite;
}

.portfolio-bg {
  background-image: url("./assets/images/bg_pattern-1.svg");
  background-repeat: no-repeat;
  background-size: "cover";
  background-position: right center;
}
.expert-bg {
  background-image: url("./assets/images/star-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* background-position: right center; */
}

.pulse {
  animation: animate 3s infinite;
}

@keyframes animate {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 242, 255, 0.5);
  }
  40% {
    box-shadow: 0 0 0 10px rgba(0, 242, 255, 0);
  }
  80% {
    box-shadow: 0 0 0 10px rgba(0, 242, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 242, 255, 0);
  }
}

.radial-gradient-bg1 {
  background: radial-gradient(circle at center 100%, #45c4b0 40%, #000000);
}
.radial-gradient-bg2 {
  background: radial-gradient(circle at center 60%, #45c4b0 40%, #000000);
}
.radial-gradient-bg3 {
  background: radial-gradient(circle at 50% 100%, #45c4b0 20%, #000000 90%);
}
.radial-gradient-bg4 {
  background: radial-gradient(circle at 50% 100%, #5153c0 20%, #000000 90%);
}
.radial-gradient-bg5 {
  background: radial-gradient(circle at 50% 100%, #456abf 20%, #000000 90%);
}
.radial-gradient-bg6 {
  background: radial-gradient(circle at 50% 100%, #41b3a8 20%, #000000 90%);
}
.radial-gradient-bg7 {
  background: radial-gradient(circle at 180% 200%, #45c4b0 40%, #000000);
}

.opacity-0 {
  opacity: 0;
}
.opacity-100 {
  opacity: 1;
}

.container {
  filter: opacity(4%);
}
a.btn-6 {
  text-decoration: none;
  line-height: 50px;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
}
a.btn-5 {
  text-decoration: none;
  line-height: 50px;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
}

[class^="btn-6"] {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  height: 70px;
  max-width: 250px;
  margin: 1rem auto;
  border: 2px solid currentColor;
  border-radius: 999px;
}
[class^="btn-5"] {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  height: 50px;
  max-width: 150px;
  margin: 1rem auto;
  border: 2px solid currentColor;
  border-radius: 999px;
}

.btn-6 {
  color: white;
  text-align: center;
  transition: color 0.4s ease-in-out, border-color 0.6s ease-in-out;
  z-index: 1;
}
.btn-5 {
  color: white;
  text-align: center;
  transition: color 0.4s ease-in-out, border-color 0.6s ease-in-out;
  z-index: 1;
}

.btn-6 span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%; /* Create a circular shape */
  background-color: #45c4b0;
  top: 100%; /* Start from the bottom */
  left: 100%; /* Start from the right */
  transition: width 0.6s ease-in-out, height 0.6s ease-in-out,
    top 0.6s ease-in-out, left 0.6s ease-in-out;
  z-index: -1;
  transform: translate(-50%, -50%);
}
.btn-5 span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%; /* Create a circular shape */
  background-color: #45c4b0;
  top: 100%; /* Start from the bottom */
  left: 100%; /* Start from the right */
  transition: width 0.6s ease-in-out, height 0.6s ease-in-out,
    top 0.6s ease-in-out, left 0.6s ease-in-out;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.btn-6:hover span {
  width: 400%; /* Expand to a large circle to cover the button */
  height: 400%; /* Expand to a large circle to cover the button */
  top: 50%; /* Move towards the center */
  left: 50%; /* Move towards the center */
}
.btn-5:hover span {
  width: 400%; /* Expand to a large circle to cover the button */
  height: 400%; /* Expand to a large circle to cover the button */
  top: 50%; /* Move towards the center */
  left: 50%; /* Move towards the center */
}

.btn-6:hover {
  color: black;
  border-color: #45c4b0; /* Change border color to match the background color */
}
.btn-5:hover {
  color: black;
  border-color: #45c4b0; /* Change border color to match the background color */
}

.btn-6:active {
  background-color: #45c4b0;
}
.btn-5:active {
  background-color: #45c4b0;
}

.icon {
  z-index: 1;
  transition: color 0.4s ease-in-out;
}

.group:hover .icon {
  color: black;
}
